import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ApisService} from '../../../services/apis.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  RoundTripCalenderDesktopDialogComponent
} from '../round-trip-calender-desktop-dialog/round-trip-calender-desktop-dialog.component';
import {
  TravellersClassesDesktopDialogComponent
} from '../travellers-classes-desktop-dialog/travellers-classes-desktop-dialog.component';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';

declare var $: any;

@Component({
  selector: 'app-round-trip-form',
  templateUrl: './round-trip-form.component.html',
  styleUrls: ['./round-trip-form.component.css']
})

export class RoundTripFormComponent implements OnInit, OnChanges {
  @Output() applyModificationEvent = new EventEmitter();
  // @Output() searchDone = new EventEmitter();
  @Output() searchDone = new EventEmitter<any>();
  @Output() searchInputClose = new EventEmitter();
  @Input() inputCodeAirLine: string;
  @Input() color;
  @Input() disabeled: boolean = true;
  @Input() classTrip: boolean = false;
  @Input() payLater;

  // @Input() searchDone:boolean = true;
  // @Output() ishidden=false;
  @Input() className: any;
  totalTravellers: any = 1;
  adult: any = '1';
  child: any = '0';
  seat: any = '0';
  lap: any = '0';
  departureDate: any;
  returnDate: any;
  origin: any;
  originCode: any;
  destination: any;
  destinationCode: any;
  loading = false;
  errors = false;
  errorMessage = 'Please Enter All Fields To Start Search';
  errorOffersMessage = 'Minum Two Passengers Required'
  datesPrices: any;
  dialogRef: any;
  directFlight: boolean = true;
  errorOffers: boolean = false;
  stops: string;
  airline: any;
  searchRequest: any;

  // @Output()searchDone: boolean=false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private apis: ApisService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
  ) {
  }

  ngOnChanges() {

  }

  getDate(date) {
    this.departureDate = date.departureDate;
    this.returnDate = date.returnDate;
    // console.log('data selected', this.datePipe.transform(this.departureDate, 'yyyy-MM-dd'));
    // console.log('data selected', this.datePipe.transform(this.returnDate, 'yyyy-MM-dd'));

  }

  searchClose() {
    this.searchInputClose.emit('close daigram');
  }

  ngOnInit(): void {
    // $(".datepicker__input").daterangepicker({
    //   autoUpdateInput: false,
    //   locale: {
    //     cancelLabel: 'Clear'
    //   }
    // });
    //
    // $('.datepicker__input').on('apply.daterangepicker', function (ev, picker) {
    //   $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    // });
    //
    // $('.datepicker__input').on('cancel.daterangepicker', function (ev, picker) {
    //   $(this).val('');
    // });
    this.totalTravellers = 1;

    this.searchRequest = this.route.snapshot.queryParams;
    this.airline = this.inputCodeAirLine;
    if (this.disabeled) {
      if (this.localStorage.getItem('searchRequestOffers') != undefined) {
        this.searchRequest = JSON.parse(this.localStorage.getItem('searchRequestOffers'));
        this.loadOldSearch(this.searchRequest);
      }
    } else if (this.localStorage.getItem('searchRequest') != undefined) {
      this.searchRequest = JSON.parse(this.localStorage.getItem('searchRequest'));
      this.loadOldSearch(this.searchRequest);
    }
    if (this.searchRequest.fullOrigin != '' && this.searchRequest.fullOrigin) {
      this.loadOldSearch(this.searchRequest);
    }
  }

  setOrigin(e) {
    this.origin = e;
    if (this.origin && this.origin.length > 3) {
      this.originCode = this.origin.slice(0, 3);
    }
  }

  setDestination(e) {
    this.destination = e;
    if (this.destination && this.destination.length > 3) {
      this.destinationCode = this.destination.slice(0, 3);
    }
  }

  setDirectFlight(status): void {
    this.directFlight = status;

    if (this.directFlight === true) {
      this.stops = '0';
    } else {
      this.stops = null;
    }
  }

  setAirLine(airline): void {
    setTimeout(() => {
      this.airline = airline;
    }, 1);
  }

  openTravellersDialog() {

    this.dialogRef = this.matDialog.open(TravellersClassesDesktopDialogComponent, {
      panelClass: 'travellersClassesDesktopDialog',
      autoFocus: false,
      maxHeight: '90vh',
      width: '60%',
      data: {
        totalTravellers: this.totalTravellers,
        className: this.className,
        adult: this.adult,
        child: this.child,
        lap: this.lap,
        seat: this.seat,
      }
    });
    this.dialogRef.afterClosed().subscribe((data) => {

      if (data) {
        data.className ? this.className = data.className : this.className = 'Economy';
        data.totalTravellers ? this.totalTravellers = data.totalTravellers : this.totalTravellers = '1';
        data.adult ? this.adult = data.adult : this.adult = '1';
        data.child ? this.child = data.child : this.child = '0';
        data.lap ? this.lap = data.lap : this.lap = '0';
        data.seat ? this.seat = data.seat : this.seat = '0';
      }
      localStorage.setItem('searchRequestOffers', JSON.stringify(this.initRequest()));
    });
  }

  getTravellersDetails(data: any) {
    if (data) {
      data.className ? this.className = data.className : this.className = 'Economy';
      data.totalTravellers ? this.totalTravellers = data.totalTravellers : this.totalTravellers = '1';
      data.adult ? this.adult = data.adult : this.adult = '1';
      data.child ? this.child = data.child : this.child = '0';
      data.lap ? this.lap = data.lap : this.lap = '0';
      data.seat ? this.seat = data.seat : this.seat = '0';
    }
    localStorage.setItem('searchRequestOffers', JSON.stringify(this.initRequest()));
  }

  openDesktopCalender(e): void {
    this.dialogRef = this.matDialog.open(RoundTripCalenderDesktopDialogComponent, {
      panelClass: 'roundTripCalenderDesktopDialog',
      maxWidth: '60vw',
      data: {
        origin: this.origin ? this.origin.slice(0, 3) : undefined,
        destination: this.destination ? this.destination.slice(0, 3) : undefined,
        departDate: this.departureDate ? this.departureDate : undefined,
        returnDate: this.returnDate ? this.returnDate : undefined,
        type: e,
      }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.departureDate = data.departureDate;
        this.returnDate = data.returnDate;
      }
    });
  }

  initRequest() {


    return {
      fullOrigin: this.origin,
      fullDestination: this.destination,
      trip_type: 'Return',
      departure_date: this.datePipe.transform(this.departureDate, 'yyyy-MM-dd'),
      return_date: this.datePipe.transform(this.returnDate, 'yyyy-MM-dd'),
      origin: this.originCode,
      destination: this.destinationCode,
      class: this.className,
      travellers: this.totalTravellers,
      adult: this.adult,
      child: this.child,
      lap: this.lap,
      seat: this.seat,
      stops: this.disabeled ? this.stops : '0',
      airlines: this.inputCodeAirLine,
      offers: this.disabeled,
      payLater: this.payLater
    };

  }

  loadOldSearch(searchRequest: any) {
    // this.searchRequest = JSON.parse(localStorage.getItem('searchRequest'));
    if (searchRequest) {
      this.totalTravellers = this.searchRequest.travellers;
      if (this.searchRequest.adult) {
        this.adult = this.searchRequest.adult;
      }
      if (this.searchRequest.child) {
        this.child = this.searchRequest.child;
      }
      if (this.searchRequest.lap) {
        this.lap = this.searchRequest.lap;
      }
      if (this.searchRequest.seat) {
        this.seat = this.searchRequest.seat;
      }
      this.className = this.searchRequest.class;
      this.departureDate = this.searchRequest.departure_date;
      this.returnDate = this.searchRequest.return_date;
      this.originCode = this.searchRequest.origin;
      this.origin = this.searchRequest.fullOrigin;
      this.destination = this.searchRequest.fullDestination;
      this.destinationCode = this.searchRequest.destination;
      this.stops = this.disabeled ? this.searchRequest.stops : '0';
      this.airline = this.searchRequest.airlines;

    }
  }

  appendToRoute() {

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.initRequest(),
      queryParamsHandling: 'merge',
      skipLocationChange: true
    }).then(() => {
      this.applyModificationEvent.emit(this.route.snapshot.queryParams);
    });
  }

  submit() {

    if (!this.totalTravellers || !this.className || !this.departureDate || !this.returnDate || !this.origin || !this.destination) {
      this.errors = true;
    } else {
      if (this.totalTravellers < 2 && !this.disabeled) {
        this.errorOffers = true;
      } else {
        this.appendToRoute();
        this.payLater = true;
        if (this.payLater) {
          localStorage.setItem('searchRequestOffers', JSON.stringify(this.initRequest()));

        }
        if (!this.disabeled && !this.payLater) {
          localStorage.setItem('searchRequestOffers', JSON.stringify(this.initRequest()));
        }
        if (!this.payLater && this.disabeled) {
          localStorage.setItem('searchRequest', JSON.stringify(this.initRequest()));
        }

        this.router.navigate(['flights'], {queryParams: this.initRequest()});
      }
    }

  }
}

