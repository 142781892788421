import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LocalStorageService } from "../../../services/webApis/local-storage.service";
import { ReviewFlightAddTravellerComponent } from "../review-flight-add-traveller/review-flight-add-traveller.component";
import { SEOService } from "../../../services/seo.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ThanksModalComponent } from "../thanks-modal/thanks-modal.component";
import { ApisService } from "src/app/services/apis.service";
import { CookieService } from "ngx-cookie-service";
import { LoadingModalComponent } from "../../loading-modal/loading-modal.component";


@Component({
  selector: "app-review-trip-travellers-data",
  templateUrl: "./review-trip-travellers-data.component.html",
  styleUrls: ["./review-trip-travellers-data.component.css"],
})
export class ReviewTripTravellersDataComponent implements OnInit, OnChanges {
  @Output() updateSelectedTravellersEvent = new EventEmitter();
  @Output() updateSelectedTravellersOffersEvent = new EventEmitter();
  @Output() nextStepEvent = new EventEmitter();
  @Output() previousStepEvent = new EventEmitter();
  @Input() searchRequest: any;
  @Input() selectedTravellers: any = [];
  @Input() countries;
  @Output() countriesOut = new EventEmitter();
  @Output() accountOut = new EventEmitter();
  @Output() ownerinfo = new EventEmitter();
  @Input() accountInfo;
  @Input() OwnerInfo;
  @Input() familyMembers: any[];
  @Input() FamilyRelation1;
  @Output() familyMember2 = new EventEmitter();
  @Output() familyRelation = new EventEmitter();
  @Output() setSelectedTravellers = new EventEmitter();
  @Output() reloadFamilyMember = new EventEmitter();
  @Output() dataPnr_Output = new EventEmitter();
  @Input() offers = 'false';
  TravellerStep = 1;
  currentTraveller = 0;
  token: any;
  validTravellers: boolean = false;
  travellersMember: any[] = [];
  // validTravellersNoAuth:boolean = false;
  lenthFormMember: number = 0;
  moveToPayment: any = false;
  selectedTravellersChange = false;
  numberOfTeravellers: any[] = [];
  dialogRef: any;
  localFlight: any;
  noAuthTravellers: any = [];
  anotherTraveller: any = false;
  enable: any = false;
  tagId: any;
  informationContact: any
  payLater: string;
  dataPnr: any;
  messageError: any;
  payLaterData: any;
  loading: boolean;
  MultiTicket: string;
  secondTagId: string;
  firstIndex: string;
  secondIndex: string;
  familyFalse = false
  loadingFilght: any;
  constructor(
    private matDialog: MatDialog,
    private localStorage: LocalStorageService,
    private seoService: SEOService,
    private _ActivatedRoute: ActivatedRoute,
    private apis: ApisService,
    private cookie: CookieService,
    private _Router: Router,

  ) {


    // this.MultiTicket=this._ActivatedRoute.snapshot.queryParamMap.get('multiTicket');
    // if(this.MultiTicket=='true'){
    //   this.secondTagId=this._ActivatedRoute.snapshot.queryParamMap.get('second_tag_id');
    //   this.firstIndex=this._ActivatedRoute.snapshot.queryParamMap.get('firstIndexId');
    //   this.secondIndex=this._ActivatedRoute.snapshot.queryParamMap.get('second_index_id');
    // }
  }

  ngOnInit(): void {
    this.tagId = this._ActivatedRoute.snapshot.paramMap.get('tagId')
    this.payLater = this.searchRequest.payLater
    if (this.localStorage.getItem("flyallover_token") != 'undefined') {
      this.token = this.localStorage.getItem("flyallover_token");
      // const noAuthTravellers = JSON.parse(
      //   this.localStorage.getItem("noAuthTravellers")
      // );
      // if (noAuthTravellers && !this.token) {
      //   if (noAuthTravellers.length > 0) {
      //     this.anotherTraveller = true;
      //   }
      //   this.noAuthTravellers = noAuthTravellers;
      // }
      this.numberOfTeravellers.length = this.searchRequest.travellers;
      console.log('test num teravellers', this.numberOfTeravellers.length);

    }

    if (this.familyMembers) {

      this.familyFalse = true
    }
    this.seoUpdate();
    console.log('searchRequestData', this.searchRequest);
    console.log('selectedTravellerstData', this.selectedTravellers);
  }

  getcountry() {
    this.countriesOut.emit();
  }

  accountinfo() {
    this.accountOut.emit();
  }

  ownerInfo() {
    this.ownerinfo.emit();
  }

  getMembers() {
    this.familyMember2.emit();
  }

  getFamilyRelation() {
    this.familyRelation.emit();
  }

  seoUpdate() {
    this.seoService.updateTitle("Review Flight");
    this.seoService.updateDescription(
      "Get all information about trips, flights and hotels with your profile in flyallover just sign up "
    );
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  reloadMember() {
    this.reloadFamilyMember.emit()
  }
  ngOnChanges(changes) {
    if (changes.searchRequest && changes.searchRequest.currentValue) {
      const countryFrom = changes.searchRequest.currentValue.destination
        .trim()
        .toLowerCase();
      const CountryTo = changes.searchRequest.currentValue.origin
        .trim()
        .toLowerCase();
      if (countryFrom === CountryTo) {
        this.localFlight = true;
      }
    }
  }
  selectTraveller(e): void {
    console.log('e => selectTraveller ', e);

    if (e.passport?.passport_number != undefined) {
      var travellerGender = "";
      if (e.gender === "Male") {
        travellerGender = "M";
      }
      if (e.gender === "Female") {
        travellerGender = "F";
      }
      this.travellersMember.push({
        GivenName: e.first_name,
        MiddleName: e.middle_name,
        Surname: e.last_name,
        family_member_id: e.id,
        traveller_dob: e.date_of_birth,
        passport_number: e.passport.passport_number,
        expiry_date: e.passport.expire_date,
        issue_date: e.passport.issue_date,
        nationality_id: e.nationality.id.toString(),
        nationality_code: e.nationality.code,
        country_id: e.country.id.toString(),
        country_code: e.country.code,
        "country-code": e.country.code,
        countryPassport: e.passport.country.code,
        nationality: e.nationality.code,
        issuedCountry: e.passport.issued_country.code,
        traveller_gender: travellerGender,
        phone_type: "H",
        PassengerType: e.family_relation.passenger_type,
        color: "#e67e22",
      });
      this.sendTravellersMembers();

    } else {
      alert('add passport number')
    }
  }
  // in offers
  allFormData(travellerForm) {


    const object: any = {};
    const travelarFormSort = [];
    let HOF = []
    let SPS = []
    let FNN = []
    HOF = travellerForm.filter((x) => x.PassengerType == "HOF");
    HOF.forEach((item) => { travelarFormSort.push(item) })
    SPS = travellerForm.filter((x) => x.PassengerType == "SPS");
    SPS.forEach((item) => { travelarFormSort.push(item) })
    FNN = travellerForm.filter((x) => x.PassengerType == "FNN");
    FNN.forEach((item) => { travelarFormSort.push(item) })
    for (let i = 0; i < travellerForm.length; i++) {
      object[i + 1] = {};
      object[i + 1] = travellerForm[i];
    }
    if (this.MultiTicket == 'true') {
      console.log('multi ticket true')
      object.tagId = this.secondTagId;
      object.email = this.informationContact.value.email;
      object.phone = this.informationContact.value.phone;
      object.origin = this.searchRequest.origin;
      object.destination = this.searchRequest.destination;
      object.departure_date = this.searchRequest.departure_date;
      object.return_date = this.searchRequest.return_date;
      object.passengerscount = this.searchRequest.travellers.toString();
      object.trip_type = this.searchRequest.trip_type;
      object.origin_country = this.getOriginCountry();
      object.destination_country = this.getDestinationCountry();
      object.CreditCardCity = this.informationContact.value.CreditCardCity;
      object.CreditCardstreetAddress = this.informationContact.value.CreditCardstreetAddress;
      object.cardHolderName = this.informationContact.value.cardHolderName;
      object.CreditCardCountry = this.informationContact.value.CreditCardCountry;
      object.CreditCardZip = this.informationContact.value.CreditCardZip;
      console.log('multi ticket true read object', object)
      return object;
    }
    object.tagId = this.tagId;
    object.email = this.informationContact.value.email;
    object.phone = this.informationContact.value.phone;
    object.origin = this.searchRequest.origin;
    object.destination = this.searchRequest.destination;
    object.departure_date = this.searchRequest.departure_date;
    object.return_date = this.searchRequest.return_date;
    object.passengerscount = this.searchRequest.travellers.toString();
    object.trip_type = this.searchRequest.trip_type;
    object.origin_country = this.getOriginCountry();
    object.destination_country = this.getDestinationCountry();
    object.CreditCardCity = this.informationContact.value.CreditCardCity;
    object.CreditCardstreetAddress = this.informationContact.value.CreditCardstreetAddress;
    object.cardHolderName = this.informationContact.value.cardHolderName;
    object.CreditCardCountry = this.informationContact.value.CreditCardCountry;
    object.CreditCardZip = this.informationContact.value.CreditCardZip;
    console.log('multi ticket false read object', object)
    return object;

  }
  getOriginCountry() {
    const arr = this.searchRequest.origin.split(",");
    return arr[arr.length - 1];
  }

  getDestinationCountry() {
    const arr = this.searchRequest.destination.split(",");
    return arr[arr.length - 1];
  }
  getInformationContact(event) {
    this.informationContact = event
    this.validTravellers = ((this.searchRequest.travellers + this.lenthFormMember) == this.travellersMember.length && this.informationContact.valid) ? true : false
  }
  lenthFormAddTraveller($event) {
    this.lenthFormMember = $event.value.length
    this.validTravellers = ((this.searchRequest.travellers + this.lenthFormMember) == this.travellersMember.length && this.informationContact.valid) ? true : false
  }
  sendTravellersMembers() {
    this.selectedTravellersChange = true;
    // this.selectedTravellers.push(this.travellersMember);
    this.validTravellers = this.searchRequest.travellers == this.travellersMember.length ? true : false
    if (this.offers == 'false') {
      this.validTravellers = ((this.searchRequest.travellers + this.lenthFormMember) == this.travellersMember.length && this.informationContact.valid) ? true : false
      this.localStorage.setItem('selectedTravellers', JSON.stringify(this.allFormData(this.travellersMember)))
    } else {
      this.updateSelectedTravellersEvent.emit(this.travellersMember);
    }
  }
  unSelectTraveller(e): void {

    this.travellersMember.forEach((traveller: any) => {
      if (
        e.first_name == traveller.GivenName &&
        e.middle_name == traveller.MiddleName &&
        e.last_name == traveller.Surname
      ) {
        this.travellersMember.splice(
          this.travellersMember.indexOf(traveller),
          1
        );
      }
    });
    this.validTravellers = this.searchRequest.travellers == this.travellersMember.length ? true : false
    if (this.offers == 'false') {
      this.validTravellers = ((this.searchRequest.travellers + this.lenthFormMember) == this.travellersMember.length && this.informationContact.valid) ? true : false
      this.localStorage.setItem('selectedTravellers', JSON.stringify(this.allFormData(this.travellersMember)))
    } else {
      this.updateSelectedTravellersEvent.emit(this.travellersMember);
    }
  }

  deleteTraveller(e): void {
    const index = this.noAuthTravellers.findIndex((obj) => obj.id === e.id);
    if (index > -1) {
      this.noAuthTravellers.splice(index, 1);
    }
    if (this.noAuthTravellers.length == 0) {
      this.anotherTraveller = false;
    }
  }

  openAddTravellerDialog(): void {
    this.dialogRef = this.matDialog.open(ReviewFlightAddTravellerComponent, {
      panelClass: "ReviewFlightAddTravellerComponent",
      maxHeight: "100vh",
      width: "50%",
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data && data !== true) {
        this.selectTraveller(data);
        this.noAuthTravellers.push(data);
        this.localStorage.setItem(
          "noAuthTravellers",
          JSON.stringify(this.noAuthTravellers)
        );
      }
    });
  }

  checkValidate(e) {
    this.enable = e;
  }
  getPayLater(e) {
    this.informationContact = e.initFormContact

    console.log('travellers', e);

    this.payLaterData = this.allFormData(e.travellers);

    this.validTravellers = e.validTravellers
    console.log('review trip payload', this.informationContact, this.payLaterData, this.validTravellers);


  }
  setOwner(e): void {
    this.noAuthTravellers = e;
    this.checkTravellers(e);
    this.setSelectedTravellers.emit(this.noAuthTravellers)
    // this.localStorage.setItem(
    //   "selectedTravellers",
    //   JSON.stringify(this.selectedTravellers)
    // );
    // this.localStorage.setItem(
    //   "noAuthTravellers",
    //   JSON.stringify(this.noAuthTravellers)
    // );
    this.anotherTraveller = true;
    if (this.offers == 'false') {
      // this.validTravellers =  e.passengerscount == this.searchRequest.travellers ;
      this.validTravellers = this.searchRequest.travellers == e.passengerscount ? true : false

    } else {
      this.validTravellers = true;
    }
  }

  checkTravellers(e): void {
    if (e.passport_number || this.localFlight || true) {
      this.selectedTravellersChange = true;
      this.selectedTravellers = e;
      // this.nextStepEvent.emit(this.selectedTravellers);
    }
  }
  createPnr() {
    this.loading = true;
    this.validTravellers = false
    this.openLoadingModal();
    // if (this.MultiTicket=='true') {
    //   this.apis.createMultiTicketPnr(this.payLaterData,this.token).subscribe({
    //     next: (res: any) => {
    //       this.loading = false;
    //       this.dataPnr = res.data;
    //       if (this.dataPnr.access_token != "") {
    //         this.localStorage.setItem('flyallover_token', this.dataPnr.access_token)
    //         this.cookie.set('flyallover_token', this.dataPnr.access_token);
    //       }
    //       localStorage.setItem('user_id', res.data.user.id);
    //       localStorage.setItem('email_log', res.data.user.email);
    //       this.openThanksModal();
    //     },
    //     error: (err: any) => {
    //       this.loading = false;
    //       this.messageError = err.error.error;
    //       this.openThanksModal();
    //     }
    //   })
    // }else{
    console.log('paylater payload', this.payLaterData);

    this.apis.createOnlyPnr(this.payLaterData).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.dataPnr = res.data;
        this.dataPnr_Output.emit(this.dataPnr);
        console.log(this.dataPnr, 'this.dataPnr')
        if (this.dataPnr.access_token != "") {
          this.localStorage.setItem('flyallover_token', this.dataPnr.access_token)
          this.cookie.set('flyallover_token', this.dataPnr.access_token);
        }
        this.localStorage.setItem('order_pnr',this.dataPnr.order.pnr)
        this.localStorage.setItem('order_id',this.dataPnr.order.id)
        localStorage.setItem('user_id', res.data.user.id);
        localStorage.setItem('email_log', res.data.user.email);
        this.loadingFilght.close()
        this.apis.continueToPaymentStep.next(3);
      },
      error: (err: any) => {
        this.loading = false;
        this.messageError = err.error.error;
        this.openThanksModal();
      }
    })
  }
  closeDialog() {
    this.dialogRef.close();
  }
  openThanksModal(): void {
    this.dialogRef = this.matDialog.open(ThanksModalComponent, {
      panelClass: "pnrTicketDialog",
      autoFocus: false,
      maxHeight: "95vh",
      width: '440px',
      backdropClass: "background-transparent",
      data: {
        dataKey: this.dataPnr,
        error: this.messageError,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      //this._Router.navigate(["/"]);
      console.log('data', this.dataPnr);
      localStorage.setItem('order_id', this.dataPnr.order.id);
      localStorage.setItem('order_pnr', this.dataPnr.order.pnr);
    });
  }
  openLoadingModal(): void {
    this.loadingFilght = this.matDialog.open(LoadingModalComponent, {
      panelClass: "alternativeDateDialog",
      autoFocus: false,
      maxHeight: "95vh",
      maxWidth: "95vw",
      backdropClass: "background-transparent",

    })

  }
}
