import {AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {ApisService} from '../../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {DOCUMENT} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AddMemberComponent} from '../../dashboard/add-family-member/add-member.component';
import {DeleteMemberComponent} from '../../dashboard/delete-member/delete-member.component';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';
import {SEOService} from '../../../services/seo.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReviewFlightAddMemberTravellerComponent } from '../review-flight-add-member-traveller/review-flight-add-member-traveller.component';


@Component({
  selector: 'app-review-flight-offers-family-members',
  templateUrl: './review-flight-offers-family-members.component.html',
  styleUrls: ['./review-flight-offers-family-members.component.css']
})
export class ReviewFlightOffersFamilyMembersComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() selectable;
  @Input() payLater
  @Input() searchRequest: any;
  @Output() selectTravellerEvent = new EventEmitter();
  @Output() unSelectTravellerEvent = new EventEmitter();
  @Output() setPayLater = new EventEmitter();
  addTraveler = false;
  showTraveler = false;
  passportInfo = false;
  addPassport = false;
  travellers:any[]=[]
  numberOfTeravellers = [];
  memberId: any;
  memberName: any;
  memberRelation: any;
  token: any;
  name: any;
  countries: any;
  relations: any;
  dialogRef: any;
  owner: any;
  noPassport: any = [];
  localFlight: any;
  newTempMembers: any = [];
  selected:boolean = false;
  form: FormGroup;
  Countries2:any;
  filteredCountries: Observable<string[]>;
  message:any;
  right:any ;
  errorCheckEmail:any ;
  spinner:any;
  traveller:boolean = false
  @Input() getCountries1;
  @Input() accountInfo;
  @Input() OwnerInfo;
  @Input() familyMembers;
  @Input() familyRelation1;
  @Output() countriesOut = new EventEmitter();
  @Output() accountInfo2 = new EventEmitter();
  @Output() ownerinfo = new EventEmitter();
  @Output() familyMember2 = new EventEmitter();
  @Output() familyRelation = new EventEmitter();
  @Output() informationContact = new EventEmitter();
  @Output() lenthFormAddTraveller = new EventEmitter();
  @Output() reloadFamilyMember = new EventEmitter();

  @ViewChild(ReviewFlightAddMemberTravellerComponent) addMemberComponent:ReviewFlightAddMemberTravellerComponent;
  travellersData: any[]=[];

  constructor(
    private apis: ApisService, private cookie: CookieService,
    private router: Router, public datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog, private localStorage: LocalStorageService,
    private seoService: SEOService,
    ) {
    this.form = this.initForm();
  }

  ngOnInit(): void {
    this.token = this.localStorage.getItem('flyallover_token');
    this.countriesOut.emit();
    this.accountInfo2.emit();
    this.ownerinfo.emit();
    this.familyMember2.emit();
    this.seoUpdate();
    this.form.valueChanges.subscribe(res=>{
      this.informationContact.emit(this.form);
      // console.log('this.form ==>',this.form);
    })
    this.numberOfTeravellers.length = this.searchRequest.travellers;
    this.travellers.length = this.numberOfTeravellers.length;
  }

  ngAfterViewInit() {
    this.getAccountInfo();
    this.getCountries();
    this.owner = this.OwnerInfo;
  }

  // getCountries() {
  //   this.countries = this.getCountries1;
  // }

  getAccountInfo() {
    if (this.accountInfo) {
      this.name = this.accountInfo.data.first_name.toUpperCase() + ' ' + this.accountInfo.data.last_name.toUpperCase();
    }
  }

  seoUpdate() {

    this.seoService.updateTitle('Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  initForm(): FormGroup {
    return new FormGroup({
      // Billing and Contact Information
      phone: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required,Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
      CreditCardCountry: new FormControl("Egypt", ),
      CreditCardstreetAddress: new FormControl("20 new way", ),
      CreditCardCity: new FormControl("Cairo", [

        Validators.pattern("[a-zA-Z]{2,}"),
      ]),
      CreditCardZip: new FormControl("12154151", [

        Validators.pattern("[0-9]{1,100}"),
      ]),
      cardHolderName :new FormControl("",[Validators.required,Validators.pattern("^[a-zA-Z]{3,}(?: [a-zA-Z]+){1,}$")])
    });
  }
  get f() {
    return this.form.controls;
  }
  addMember(){
    this.traveller = true;
    this.addMemberComponent.addTraveller()
  }
  ngOnChanges(changes) {
    if (changes.searchRequest && changes.searchRequest.currentValue) {
      const countryFrom = changes.searchRequest.currentValue.fullDestination.split(',')[2].trim().toLowerCase();
      const CountryTo = changes.searchRequest.currentValue.fullOrigin.split(',')[2].trim().toLowerCase();
      if (countryFrom === CountryTo) {
        this.localFlight = true;
      }
    }
  }
  reloadMember(){
    this.reloadFamilyMember.emit()
  }
  opedAddMemberDialog() {
    this.dialogRef = this.matDialog.open(AddMemberComponent, {
      panelClass: 'ReviewFlightAddMemberDialog',
      autoFocus: false,
      width: '80%',
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.newTempMembers.push(data);
        this.familyMember2.emit();
      }
    });
  }

  // setEmails(e) {
  //   if(this.f['email'].dirty && !this.f['email'].errors?.email){
  //       this.spinner = true;
  //       this.apis.checkMail(e.target.value).subscribe(
  //         (data: any) => {
  //           if (data.status) {
  //             this.spinner = false;
  //             this.right = true;
  //             this.form.patchValue({email:e.target.value});
  //             this.errorCheckEmail = false;
  //           }
  //         },
  //         (err) => {
  //           this.spinner = false;
  //           this.right = false;
  //           this.errorCheckEmail = true;
  //           this.message = "The email has already been taken.";
  //         }
  //       );
  //     }
  // }
  info(id) {
    this.addPassport = false;
    this.passportInfo = false;
    this.memberId = id;
  }

  getPassportInfo(id, name, relation = '') {
    this.memberId = id;
    this.memberName = name;
    this.memberRelation = relation;
  }

  newPassport(id) {
    this.memberId = id;
  }

  openDialog(id): void {
    this.dialogRef = this.matDialog.open(DeleteMemberComponent, {
      panelClass: 'delete-member',
      width: '50%',
      data: id
    });
  }

  closePassport() {
    this.passportInfo = false;
  }

  closeMember() {
    this.showTraveler = false;
  }

  closeAddPassport() {
    this.addPassport = false;
  }
  setMemberTraveller($event:any[]){
    let e = {checked:true}
    $event.forEach((item)=>{
      this.unSelectTravellerEvent.emit(item);
      // console.log('event member ==>',item,e.checked );
      this.toggleCheck(e,item)
    })
  }
  removeMember($event){
    // console.log('deleted ==>', $event );

    this.unSelectTravellerEvent.emit($event);
  }
  lenthFormMember($event){
    this.lenthFormAddTraveller.emit($event)
  }
  toggleCheck(e, member) {
    if(e.checked == true && member.passport == null){
      this.selected = false;
      alert('please add passport number')
      return
    }
    if (e.checked == true) {
      console.log('islam ===> selectTravellerEvent' ,member,this.form);
      this.travellersData.push(member)
      this.changeTravellersFormat();
      // console.log('islam ===> selectTravellerEvent' ,this.travellers,this.form);
      this.selectTravellerEvent.emit(member);

      this.informationContact.emit(this.form);
      this.setPayLater.emit({travellers:this.travellers,initFormContact:this.form,validTravellers:true});
    } else {
      this.unSelectTravellerEvent.emit(member);
    }
    if (!this.localFlight && !member.passport) {
      this.noPassport[this.owner.id] = true;
    }
  }
  changeTravellersFormat() {
    this.travellers = []
    for (let i = 0; i < this.numberOfTeravellers.length; i++) {
      let formTraveller = this.travellersData[i]

      // console.log('index ',i,formTraveller);
      if (!this.numberOfTeravellers[i]){
        return
      }
      var travellerGender = "";
      if (formTraveller?.gender === "Male") {
        travellerGender = "M";
      }
      if (formTraveller?.gender === "Female") {
        travellerGender = "F";
      }
      this.travellers.push({
        GivenName: formTraveller.first_name,
        MiddleName: formTraveller.middle_name,
        Surname: formTraveller.last_name,
        family_member_id: "0",
        family_relation_id: !this.payLater? this.getFamilyObject(formTraveller.PassengerType).id.toString():"1",
        traveller_dob: formTraveller.date_of_birth,
        passport_number:!this.payLater? formTraveller.passport.passport_number:"124554845",
        expiry_date:!this.payLater? formTraveller.passport.expire_date:"2026-01-01",
        issue_date:!this.payLater?formTraveller.passport.issue_date:"2022-01-01",
        nationality_id: !this.payLater?this.getCountryObject(formTraveller.passport.issued_country).id.toString():"42",
        nationality_code: !this.payLater? this.getCountryObject(formTraveller.passport.issued_country).code:"EG",
        country_id:!this.payLater? this.getCountryObject(formTraveller.passport.issued_country).id.toString():"42",
        country_code: !this.payLater? this.getCountryObject(formTraveller.passport.issued_country).code:"EG",
        'country-code': !this.payLater? this.getCountryObject(formTraveller.passport.issued_country).code:"EG",
        countryPassport: !this.payLater? this.getCountryObject(formTraveller.passport.issued_country).code:"EG",
        nationality: !this.payLater?this.getCountryObject(formTraveller.passport.issued_country).code:"EG",
        issuedCountry: !this.payLater? this.getCountryObject(formTraveller.passport.issued_country).code:"EG",
        traveller_gender: travellerGender,
        phone_type: "H",
        PassengerType: !this.payLater? this.getFamilyObject(formTraveller.PassengerType).passenger_type:"ADT",
        color: "#e67e22"
      });
    }
  }
    getCountryObject(country): any {
    let objCountry = this.countries.find((obj) => obj.name === country);
    return objCountry
  }
  getFamilyObject(relation): any {
    const objRelation = this.relations.find((obj) => obj.name === relation);
    return objRelation
  }
  getCountries() {
    this.apis.getCountries().subscribe(
      (data: any) => {
        // console.log('dafdfdfasfasf==============> ', data);

        this.Countries2 = data
        // this.passportCountries = data;
        // this.passportNationalities = data;
        // this.passportIssuedCountries = data;
        // this.personalCountries = data;
        // this.personalNationalities = data;
        // this.filteredPersonalCountries = this.personalCountries;
        // this.filteredPersonalNationalities = this.personalNationalities;
        // this.filteredPassportCountries = this.personalNationalities;
        // this.filteredPassportNationalities = this.personalNationalities;
        // this.filteredNationalitiesCountries = this.personalNationalities;
        // this.filteredPassportIssuedCountries = this.passportIssuedCountries;
        if (this.Countries2) {
          this.filteredCountries =
            this.form.controls.CreditCardCountry.valueChanges.pipe(
              startWith(""),
              map((value) => this._filterCountries(value))
            );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  private _filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.Countries2.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["CreditCardCountry"].setErrors({
        incorrect: true,
        someProp: "Country Not Found Please Select Valid Country",
      });
    } else {
      return this.Countries2.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
}
