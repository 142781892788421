import {OnInit, Component, EventEmitter, Inject, Output, Input} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ApisService} from '../../../services/apis.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TravellersClassesDesktopDialogComponent} from '../travellers-classes-desktop-dialog/travellers-classes-desktop-dialog.component';
import {OneWayCalenderDesktopDialogComponent} from '../one-way-calender-desktop-dialog/one-way-calender-desktop-dialog.component';
import { LocalStorageService } from 'src/app/services/webApis/local-storage.service';

@Component({
  selector: 'app-one-way-trip-form',
  templateUrl: './one-way-trip-form.component.html',
  styleUrls: ['./one-way-trip-form.component.css']
})

export class OneWayTripFormComponent implements OnInit {
  @Output() applyModificationEvent = new EventEmitter();
  @Output() searchInputClose = new EventEmitter();
  @Input() color;
  @Input() disabeled:boolean = true;
  @Input() classTrip = false;
  @Input() payLater;
  @Input() className: any ;
  totalTravellers: any = '1';
  travellers: any = '1';
  travellersList: any;
  adult: any = '1';
  child: any;
  seat: any;
  lap: any;
  departureDate: any;
  origin: any;
  originCode: any;
  destination: any;
  destinationCode: any;
  loading = false;
  errors = false;
  errorMessage = 'City or Airport, Date of Departing, and Returning are required';
  datesPrices: any;
  dialogRef: any;
  directFlight: any;
  stops: any;
  airline: any;
  searchRequest: any;
  errorOffersMessage = 'Travellers must be At least 2'
  errorOffers: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private apis: ApisService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,

  ) {
  }
  close(): void {
    this.dialogRef.close();
  }
  searchClose() {
    this.searchInputClose.emit("close daigram");
  }
  getDate(date) {
    this.departureDate = date.startDate
  }
  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      this.searchRequest = this.route.snapshot.queryParams;
      if (this.localStorage.getItem('searchRequestOffers') != undefined) {
        this.searchRequest = JSON.parse(this.localStorage.getItem('searchRequestOffers'));
        this.loadOldSearch();
      }
    }
  }

  openTravellersDialog(data: any) {
    if (data) {
      data.className ? this.className = data.className : this.className = 'Economy';
      data.totalTravellers ? this.totalTravellers = data.totalTravellers : this.totalTravellers = '1';
      data.adult ? this.adult = data.adult : this.adult = '1';
      data.child ? this.child = data.child : this.child = '0';
      data.lap ? this.lap = data.lap : this.lap = '0';
      data.seat ? this.seat = data.seat : this.seat = '0';

    }
    localStorage.setItem('searchRequestOffers', JSON.stringify(this.initRequest()));
  }

  setOrigin(e) {
    this.origin = e;
    if (this.origin && this.origin.length > 3) {
      this.originCode = this.origin.slice(0, 3);
    }
  }

  setDestination(e) {
    this.destination = e;
    if (this.destination && this.destination.length > 3) {
      this.destinationCode = this.destination.slice(0, 3);
    }
  }

  setDirectFlight(status): void {
    this.directFlight = status;
    if (this.directFlight === true) {
      this.stops = '0';
    } else {
      this.stops = null;
    }
  }

  setAirLine(airline): void {
    this.airline = airline;
  }

  openDesktopCalender(): void {
    this.dialogRef = this.matDialog.open(OneWayCalenderDesktopDialogComponent, {
      panelClass: 'oneWayCalenderDesktopDialog',
      maxWidth: '35vw',
      data: {
        origin: this.origin ? this.origin.slice(0, 3) : undefined,
        destination: this.destination ? this.destination.slice(0, 3) : undefined,
        departDate: this.departureDate  ? this.departureDate : undefined,
      }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.departureDate = data.departureDate;
      }
    });
  }

  initRequest() {


    return {
      fullOrigin: this.origin,
      fullDestination: this.destination,
      trip_type: 'OneWay',
      departure_date: this.datePipe.transform(this.departureDate, 'yyyy-MM-dd'),
      origin: this.originCode,
      destination: this.destinationCode,
      class: this.className,
      travellers: this.totalTravellers,
      adult: this.adult,
      child: this.child,
      lap: this.lap,
      seat: this.seat,
      stops: this.disabeled ? this.stops : '0',
      airlines: this.airline,
      offers: this.disabeled,
      payLater:this.payLater
    };
  }

  loadOldSearch() {
    // const searchRequest = JSON.parse(localStorage.getItem('searchRequest'));
    if (this.searchRequest) {
      this.travellers = this.searchRequest.travellers;
      if (this.searchRequest.adult) {
        this.adult = this.searchRequest.adult.currentValue;
      }
      if (this.searchRequest.child) {
        this.child = this.searchRequest.child.currentValue;
      }
      if (this.searchRequest.lap) {
        this.lap = this.searchRequest.lap.currentValue;
      }
      if (this.searchRequest.seat) {
        this.seat = this.searchRequest.seat.currentValue;
      }
      this.className = this.searchRequest.class;
      this.departureDate = this.searchRequest.departure_date;
      this.originCode = this.searchRequest.origin;
      this.origin = this.searchRequest.fullOrigin;
      this.destination = this.searchRequest.fullDestination;
      this.destinationCode = this.searchRequest.destination;
      this.stops = this.disabeled ? this.searchRequest.stops : '0';
      this.airline = this.searchRequest.airlines;
    }
  }

  appendToRoute() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.initRequest(),
      queryParamsHandling: 'merge',
      skipLocationChange: true
    }).then(() => {
      this.applyModificationEvent.emit(this.route.snapshot.queryParams);
    });
  }

  submit() {
    if (!this.totalTravellers || !this.className || !this.departureDate || !this.origin || !this.destination) {
      this.errors = true;
    } else {
      if(this.totalTravellers < 2 && !this.disabeled){
      this.errorOffers = true;
      }else if(this.payLater!=true){
        this.appendToRoute();
        localStorage.setItem('searchRequest', JSON.stringify(this.initRequest()));
        this.router.navigate(['flights'], {queryParams: this.initRequest()});
      }
      else if(this.payLater==true){
        this.appendToRoute();

        localStorage.setItem('searchRequestOffers', JSON.stringify(this.initRequest()));
        this.router.navigate(['flights'], {queryParams: this.initRequest()});
      }
    }
  }
}
