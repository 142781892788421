<app-navbar></app-navbar>
<!-- start header-more-offers -->
<!-- <div class="header-blog">
  <div class="overlay">
    <div class="container">
      <h1 class=" fao_mobile_title_large text-center" style="color: #fff !important; font-size: 35px !important;">{{ title }}</h1>
    </div>
  </div>
</div> -->
<div class="header-blog">
  
  <div class="overlay">
    <img src="{{datashare?.image}}" width="100%" height="400px" style="background-size: cover; background-position: center;object-fit: cover;"> 
    <h1 class=" fao_mobile_title_large text-center topTitle" style="color: #fff !important ; position: absolute; top: 35%; right:15% ; left: 15%;" *ngIf="datashare">{{ datashare?.title }}</h1>

    <div class="container">
    </div>
  </div>
</div>

<!-- <div class="search-box">
  <input type="text" [(ngModel)]="word" placeholder="Search here..."/>
  <button>
    <img
      title="Search about trip"
      alt="search"
      src="./assets/images/blog/search.svg"
      width="30"
    />
  </button>
</div> -->

<div class="container">
    <div class="row icon-box my-5">
      <div class="col-md-7 ">
        <div class="">
          <!-- <div class="day" style="width: 9% !important;">
            <h4> Details </h4>
          </div> -->
          <h1><a >{{datashare?.title}}</a></h1>
          <p [innerHTML]="datashare?.description"></p>
        </div>
        
        </div>
        <div class="col-md-5">
          <ngb-carousel>
            <ng-template ngbSlide *ngFor="let itemImg of datashare?.images ; let i = index">
              <div class="picsum-img-wrapper">
                <img
                  src="{{itemImg.full_url}}"
                  alt="{{itemImg.file_name}}"
                  style="border-radius: 12px ; object-fit: cover;"
                  height="500px"
                  width="100%"
                />
              </div>
            
            </ng-template>
          </ngb-carousel>

          <!-- <img
                      src="{{ datashare.image}}"
                      alt="{{datashare.title}}"
                      class=" rounded w-100 mt-5"
                      style="object-fit: cover;"
                      width="100%"
                      height="90%"
                    /> -->
        </div>
    </div> 
    <div class="row icon-box my-5 " *ngFor="let item of data ; let i = index">
    
      <div class=" col-md-8  mt-4" data-aos="zoom-in" data-aos-delay="100"  >
        <div class=""  >
          <div class="day" *ngIf="item.day">
            <h4 > Day : {{item.day}}</h4>
            <!-- <h5>{{ item.country.name}} - {{item.city.name}}</h5> -->    
          </div>
        
          <h1 style="font-weight: 700;"><a>{{item.title}}</a></h1>
          <p > {{ item.description  }}</p>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <img
        src="{{ item.image}}"
        alt="{{item.title}}"
        class=" rounded w-100 mt-5"
        style="object-fit: cover;"
        width="100%"
        height="90%"
      />

    </div>
    </div>
   
    <!--         show video   -->
    <section class="my-5">
      <div class="mt-5 mx-auto">
        <!-- <app-show-video></app-show-video> -->
        <div class="video w-100">
          <video class="w-100" controls (click)="toggleVideo()" #videoPlayer>
            <source src="" type="video/mp4" />
            <!-- src="https://vjs.zencdn.net/v/oceans.mp4" -->
            Browser not supported
          </video>
        </div>
      </div>
    </section>

    <div class="row icon-box pay ">
      <div class="billing-information review-trip-section" *ngIf="!loading && !messageError">
        <form [formGroup]="form">
          <div class="row title">
          </div>
          <div class="row form border1 herefounddate">
            <div class="col-12">
              <h6 class=" title">Billing Details</h6>
              <p>
                Please Enter your card details to proceed the payment process as written on your card to avoid any mistakes.
              </p>
            </div>
            <div class="col-12 mt-2">
              <div class="row">
                <div class="col-6 item">
                  <div class="d-flex">
                    <span class="star"></span>
                    <label class="date-label">Name On Card</label>
                  </div>
                  <input formControlName="cardHolderName" type="text" class="form-control"
                    placeholder="please Enter Name On Card" id="cardHolderName"
                    (ngModelChange)="validationForm('cardHolderName')" autocomplete="off" required minlength="1"
                    maxlength="50" />
                  <mat-error *ngIf="form.controls.cardHolderName.errors?.pattern">
                    Please Type Only Letters At least Three Names</mat-error>
                  <mat-error
                    *ngIf="form.controls.cardHolderName.errors?.required && form.controls.cardHolderName.dirty">
                    Name On Card Is Requied</mat-error>
                </div>
                <div class="col-6 position-relative">
                  <div class="item ">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Card Number</label>
                    </div>
      
      
                    <div class="position-relative">
                      <input type="text" formControlName="card" class="num form-control" maxlength="16"
                        placeholder="Please Enter Card Number" autocomplete="off"
                        (ngModelChange)="testCreditCard();validationForm('card')" id="card" required />
                      <img *ngIf="vendorCode" class="vendor-image" alt="vendor-image" title="Vendor Image"
                        src="assets/images/hotels/{{vendorCode}}.png">
                    </div>
                    <mat-error *ngIf="form.controls.card.errors?.pattern"> Please Type Only
                      Numbers</mat-error>
                      <mat-error *ngIf="form.controls.card.errors?.pattern"> Card Number Should be 16 Number
                      </mat-error>
                  </div>
                </div>
      
                <div class="col-6 position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">cvc</label>
                    </div>
                    <input maxlength="4" formControlName="cvc" (ngModelChange)="validationForm('cvc')" type="text"
                      class="num form-control" placeholder="cvc" id="cvc" autocomplete="off" required />
                      <img (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')" style="top: 60%;" class="vendor-image" alt="" title="Vendor Image"
                        src="assets/images/hotels/cvc.jpg">
                  </div>
                </div>
                <div #dialog (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')" class="col-9 position-absolute dialog-cvv">
                  <div class="col-12">
                  <h1>Why do we need this?</h1>
                  <p>to Help us keep your data safe! Please provide us with your credit card verification number for added security measures.</p>
                  </div>
                  <div class="col-12 d-flex">
                    <div class="col-6">
                      <img src="assets/images/hotels/visa-cvv.png" alt="">
                      <h2>The verification number for Visa, MasterCard, Discover, Diners Club and Carte Blanche</h2>
                      <p>is the 3-digit number on the back of the credit card.</p>
                    </div>
                    <div class="col-6">
                      <img src="assets/images/hotels/American-express-cvv.png" alt="">
                      <h2>The Verification number for American Express credit cards</h2>
                      <p>is the 4-digit number located on the front of the card.</p>
                    </div>
                  </div>
                </div>
      
                <div class="col-6 position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Expiration Date</label>
                    </div>
                    <!-- <div class="d-flex justify-content-between " (keyup)="checkDate()">
                      <div class="col-5">
                        <input type="text" formControlName="CreditCardExpireDateMonth" placeholder="MM"
                          class="num month form-control text-center"  required maxlength="2"
                          (ngModelChange)="validationForm('CreditCardExpireDateMonth')" id="CreditCardExpireDateMonth"
                          (change)="updateExpireDate()" (focusout)="format($event)" autocomplete="off" />
      
                      </div>
                      <div class="col-6">
                        <input type="text" formControlName="CreditCardExpireDateYear" placeholder="YYYY"
                          class="num year form-control text-center" required
                          (ngModelChange)="validationForm('CreditCardExpireDateYear')" id="CreditCardExpireDateYear"
                          (change)="updateExpireDate()" autocomplete="off" />
                      </div>
                    </div> -->
                    <div class="d-flex justify-content-between " (keyup)="checkDate()">
                      <div class="col-5 p-0">
                        <input type="text" formControlName="CreditCardExpireDateMonth" placeholder="MM"
                              class="num month form-control text-center customInput" required
                              (ngModelChange)="validationForm('CreditCardExpireDateMonth')" id="CreditCardExpireDateMonth"
                              (change)="updateExpireDate()" (focusout)="format($event)" autocomplete="off" />
                      </div>
                      <div class="col-6 p-0">
                        <input type="text" formControlName="CreditCardExpireDateYear" placeholder="YYYY"
                              class="num year form-control text-center customInput" required
                              (ngModelChange)="validationForm('CreditCardExpireDateYear')" id="CreditCardExpireDateYear"
                              (change)="updateExpireDate()" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="errors col-12 position-relative ">
                  <!-- <div class="mat-errors">
                    <div class="b-2" *ngIf="form.controls.cvc.errors?.pattern"><p>Please Type Only 3 Numbers</p>
                    <p>4 Numbers for American Express</p></div>
                    <div class="b-2" *ngIf="form.controls.CreditCardExpireDateMonth.errors?.pattern"> Please
                      Type Only Two Numbers Such As 07</div>
                    <div class="b-2" *ngIf="errorMessageYear"> {{errorMessageYear}}</div>
                    <div class="b-2" *ngIf="form.controls.CreditCardExpireDateYear.errors?.pattern"> Please Type
                      Only Four Numbers Such As 2022</div>
                    <div class="b-2" *ngIf="errorMessageMonth"> {{errorMessageMonth}}</div>
                  </div> -->
                  <div class="mat-errors">
                    <div class="b-2 Font-Family" *ngIf="form.controls.cvc.errors?.pattern"> Please type only 3
                      numbers</div>
                    <div class="b-2 Font-Family" *ngIf="form.controls.CreditCardExpireDateMonth.errors?.pattern"> Please
                      type only two numbers such as 07</div>
                    <div class="b-2 Font-Family" *ngIf="errorMessageYear"> {{errorMessageYear}}</div>
                    <div class="b-2 Font-Family" *ngIf="form.controls.CreditCardExpireDateYear.errors?.pattern"> Please type
                      only four numbers such as 2022</div>
                    <div class="b-2 Font-Family" *ngIf="errorMessageMonth"> {{errorMessageMonth}}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 my-2  position-relative">
                  <label class="date-label Font-Family">Custom Price</label>  
                  <span class="star"></span>  
                  <div class="input-group">
          
                    <span class="input-group-text" style="z-index: 66;height: 59px;">$</span>
                    <input id="custom_amount" class=" form-control position-absolute" type="number"
                      formControlName="amount"
                      style="width: 100%; min-height: 100%; padding-left: 50px;">
                  </div>
                </div>
                <div class=" col-6 d-flex mt-4">
                  <div class="col-4 mt-3 " *ngFor="let donate of priceSelect">
                    <button class="pill" [class]="select == donate ? 'pill-select':''" (click)="selectPrice(donate)" type="button" >${{donate}}</button>

                  </div>
                  <div class="col-4 mt-3 " >
                    <button class="pill" [class]="select == datashare.price ? 'pill-select':''" (click)="selectPrice(datashare.price)" type="button" >${{datashare.price}}</button>

                  </div>
                </div>
              
              </div>
              
            
            </div>
          </div>
          <div class="row form billing
          " >
            <div class="col-12 ">
              <h6 class="title">Billing Address</h6>
              <p>Your billing address is totally secure and confidential.</p>
            </div>
            <div class="col-12 mt-2">
              <div class="row">
              <div class="col-6 position-relative">
                  <div class="item">
                        <div class="d-flex">
                          <span class="star"></span>
                          <label class="date-label">Address</label>
                        </div>
                    <input ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" formControlName="CreditCardstreetAddress" class="form-control input" />
                </div>
              </div>
              <div class="col-6 position-relative">
                <div class="item">
                  <div class="d-flex">
                    <span class="star"></span>
                    <label class="date-label">Country</label>
                  </div>
                
                  <input class="form-control" type="text"
                    formControlName="CreditCardCountry" autocomplete="on" placeholder="Country"
                    id="CreditCardCountry"
                    [ngModel]="country" (ngModelChange)="onChangeCountry($event)">
                
                </div>
                <mat-error *ngIf="form.get('CreditCardCountry').errors?.someProp">
                  {{form.get('CreditCardCountry').errors?.someProp}}
                </mat-error>
                <mat-error *ngIf="f['CreditCardCountry'].errors?.required  && f['CreditCardCountry'].dirty"> Country Is
                  Required
                </mat-error>
              </div>
                  <div class=" col-6 position-relative ">
                    <div class="item letters">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">City</label>
                      </div>
                      <input formControlName="CreditCardCity" type="text" class="form-control" placeholder="City"
                      (ngModelChange)="onChangeCity($event)" id="CreditCardCity" autocomplete="off" required  [ngModel]="city"/>
                      <mat-error *ngIf="form.controls.CreditCardCity.errors?.pattern"> Please Type Only Letters</mat-error>
                      <mat-error *ngIf="f['CreditCardCity'].errors?.required  && f['CreditCardCity'].dirty"> City Is Required
                      </mat-error>
                    </div>
                  </div>
      
                  <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">Zip</label>
                      </div>
                      <input formControlName="CreditCardZip" type="text" class="num form-control" placeholder="Zip"
                        (ngModelChange)="onChangeZip($event)" id="CreditCardZip" autocomplete="off" required [ngModel]="ZipCode" />
                    </div>
                  
                    <mat-error *ngIf="f['CreditCardZip'].errors?.required  && f['CreditCardZip'].dirty"> Zip Is Required
                    </mat-error>
                  </div>
      
                  <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">State</label>
                      </div>
                      <input formControlName="State" type="text" placeholder="State Code" class="form-control"
                      (ngModelChange)="onChangeState($event)" id="State" autocomplete="off"
                        required [ngModel]="state" />
                      <mat-error *ngIf="f['State'].errors?.required  && f['State'].dirty"> State Is Required
                      </mat-error>
                      <mat-error *ngIf="f['State'].errors?.pattern" >Please Type Only Letters</mat-error>
                    </div>
                  </div>
                  <!-- <div class="col-6 position-relative">
                    <div class="item">
                      <div class="d-flex">
                        <span class="star"></span>
                        <label class="date-label">creditCardRegion</label>
                      </div>
                      <input formControlName="creditCardRegion" type="text" placeholder="credit Card Region" class="form-control"
                      (ngModelChange)="onChangeState($event)" id="creditCardRegion" autocomplete="off"
                          />
      
                    </div>
                  </div>
      -->
      
        
        



          <div class="col-12 agree-share-contact my-3 mt-4">
            <input type="checkbox" id="agreeShareContact" name="agreeShareContact" checked >
            <label for="agreeShareContact">I consent to sharing this contact information with the Travel partner(s) associated with this booking</label>
          </div>
      
              </div>
            </div>
          </div>
          <div class="row form billing
          ">
            <div class="col-12 ">
              <h6 class="title">Contact billing information</h6>
              <p class="text-danger">We kindly request your attention to ensure that your credit card information matches your contact information. In case of any discrepancies, further information may be required to process your request efficiently.</p>
            </div>
            <div class="col-12 mt-2">
              <div class="row">
                <div class="col-6 position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Contact Name</label>
                    </div>
                    <input formControlName="name" type="text" placeholder="name" class="form-control" [(ngModel)]="name" required/>
                  
                  </div>
                </div>
                <div class="col-6 position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Contact Email</label>
                    </div>
                    <input formControlName="email" type="email" placeholder="exmple@flyallover.com" class="form-control" [(ngModel)]="contactUserEmail"
      
                      required  />
                    <mat-error *ngIf="f['email'].errors?.required  && f['email'].dirty"> Email Is Required
                    </mat-error>
                    <mat-error *ngIf="f['email'].errors?.email" >Please you email</mat-error>
                  </div>
                </div>
                <div class="col-6 position-relative">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Contact Phone</label>
                    </div>
                    <input formControlName="phone" type="text" placeholder="1234567890" class="form-control" [(ngModel)]="contactUserPhone"
      
                    required/>
                    <mat-error *ngIf="f['phone'].errors?.required  && f['phone'].dirty">Phone Is Required
                    </mat-error>
                  </div>
                </div>
                <div class="col-6">
                  <div class="item">
                    <div class="d-flex">
                      <span class="star"></span>
                      <label class="date-label">Country </label>
                    </div>
                  <!-- <mat-autocomplete >
                  <mat-option *ngFor="let option of countries | async" [value]="option.name">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete> -->
                <select class="form-select" (change)="handleSelectChange($event)">
                    <option *ngFor="let option of countries"  value=" {{option.id}}"> {{option.name}}</option>
                </select>
                  </div>
                  
                </div>
                <span for="agreeShareContact">By providing your phone number, you are giving permission to the Lead Management team to reach out to you via SMS/TEXT messages.Kindly review our
                  <a href="https://flyallover.com/privacy" title="go to privacy policy for more details" style="text-decoration: none;">privacy policy</a>  for more details</span>
              </div>
            </div>
          </div>
          <!-- <div class="text-danger text-center blockquote" *ngIf="!checkName">
            If the information provided for the traveler does not match the information on the Credit Card, additional
            verification may be required. This could involve contacting the cardholder to verify the transaction or requesting
            additional documentation to confirm the traveler's identity. In some cases, a fee of $5.00 may be charged for the
            extra security verification process.
          </div> -->
        </form>
      </div>
      <div class="navigationButtons">
        <div class="col-12 my-4">
          <div class="row justify-content-center d-flex flex-row align-items-center">
            <button   [disabled]="form.invalid" class="btn navBtn nextStepBtn" (click)="submit()">
              Book Now
            </button>
            
          </div>
        </div>
      </div>
    </div>

<!-- <div class="row icon-box my-5" *ngIf="data">
  <h2 class="text-center ">Interested ? Want more details? Join our wishlist for exclusive updates! </h2>
  <form [formGroup]="formData" (submit)="onSubmit()">
    <div class="row">
      <div class="mb-3 form-group  col-md-6  col-sm-12">
        <label for="fullname" class="form-label"> Name </label>
        <input type="text"  formControlName="name" class="form-control" id="fullname" placeholder="name" required >
      </div>
      <div class="mb-3 form-group  col-md-6  col-sm-12">
        <label for="email" class="form-label"> Email   </label>
        <input type="email"  formControlName="email" class="form-control" id="email" placeholder="email" required >
      </div>
    </div>
  
    <div class="mb-3 form-group  col-md-12  col-sm-12">
      <label for="phone" class="form-label"> Phone </label>
      <input type="text"  formControlName="phone" class="form-control" id="phone" placeholder="phone" required >
    </div>
    <div class="row">
      <div class=" mt-4 mb-3 col-sm-12 ">
        <input class="form-check-input ml-1 mt-2" type="checkbox" value="" id="defaultCheck1" (change)="check($event.target.checked)">
        <p class="ml-4 pl-2 " style="font-size: 18px;font-weight: 600;"> Are You A Travel Agent ? </p>
        <div class=" w-100 mx-auto" *ngIf="checkValue == true">
            <div class="row">
              <div class="mb-3 form-group  col-md-6  col-sm-6 mt-3">
                <label for="fullname" class="form-label"> Travel Agent Name  </label>
                <input type="text"  formControlName="agent_name" class="form-control" id="fullname" placeholder="name" required >
              </div>
              <div class="mb-3 form-group  col-md-6  col-sm-6 mt-3">
                <label for="fullname" class="form-label"> Contact Agent Name  </label>
                <input type="text"  formControlName="contact_agent_name" class="form-control" id="fullname" placeholder="name" required >
              </div>
            </div>
          <div class="row">
            <div class="mb-3 form-group  col-md-6  col-sm-6">
              <label for="email" class="form-label"> Travel Agent Email   </label>
              <input type="email"  formControlName="agent_email" class="form-control" id="email" placeholder="email" required >
            </div>
            <div class="mb-3 form-group  col-md-6  col-sm-6 ">
              <label for="phone" class="form-label"> Travel Agent  Phone </label>
              <input type="text"  formControlName="agent_phone" class="form-control" id="phone" placeholder="phone" required >
            </div>
          </div>
          </div>
      
      </div>
    </div>
    <div class="d-flex justify-content-center  mt-5">
      <button class="btn fao_mobile_btn_meduim " type="submit" [disabled]="formData.invalid"> Send </button>
    </div>
  </form>
</div> -->


    <div class="row w-50 mx-auto">
      <div class="item follow Font-Family pl-3 my-5 px-3">
                    
        <ul class=" my-2 font-icon">
          <li><h6 class="font-span_1 ">Share Now : </h6></li>
          <li>
            <a class="social-icon image-item" (click)="cop()" target="_blank">
              <img *ngIf="!copied" title="copy" style="width: 36px" alt="copy"
                src="./assets/newIcon/copy-blue-icon.svg" />
              <img *ngIf="copied" title="copied" style="width: 36px" alt="copied"
                src="./assets/newIcon/copied.png" />
            </a>
          </li>
          <li><a (click)="fb()" target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><i
                class="fab fa-facebook-f"></i></a></li>
          <li><a (click)="tw()" target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><i
                class="fab fa-twitter"></i></a></li>
          <li><a (click)="linked()" target="_blank" rel="nofollow"
              href="https://www.linkedin.com/company/flyallover"><i class="fab fa-linkedin-in"></i></a></li>
        </ul>
    </div>
    </div>
   
 
  <div class="container position-relative">
    <div class="row">
      <div class="col-12">
        <div class=" icon-box inside text-center"  *ngIf="formSubmitted && msg.status ">
          <!-- <h2 class="mb-2 fw-bold handel-thanks"> <img src="../../../assets/images/smile.png" alt="smil icon" width="40">  شكرا لكم </h2> -->
      
          <div class="img" *ngIf="msg.status == 200" >
            <img  class="handel-img" title="Contact Us " alt="mail"  src="../../../assets/images/payment.jpg" width="300px" />
          </div>
          <div class="img" *ngIf="msg.status != 200 ">
            <img  class="handel-img" title="Contact Us " alt="mail"  src="../../../assets/images/faild-pay.jpg" width="300px" />
          </div>
          <h3 class="mt-3 fw-bold" *ngIf="msg.status == 200 "> Payment has been completed successfully and we will contact you soon  </h3>
          <h3 class="mt-3 fw-bold" *ngIf="msg.status != 200 ">  Failed payment , Please try again </h3>
          <!-- {{msg.message}} -->

          <!--    <a [routerLink]="['/']">Go back to homepage <i class="fas fa-angle-right"></i></a>-->
              <div class="d-flex justify-content-center  my-5">
                                                             <div >
                  <button class="btn btn_meduim mr-3" type="submit" routerLink="/" > Home Page </button>
                </div>
                <div >
                  <button class="btn btn_meduim2 " type="submit" (click)="close()"> Close </button>
                </div>
                                                                                            
              </div>                                                                                                                     
                                               
        </div>                             
      
      </div>
    </div>
  </div>
  
</div>



<app-footer></app-footer>
