import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApisService } from "../../../services/apis.service";
import { EMPTY, Observable } from "rxjs";
import { find, map, startWith } from "rxjs/operators";
import { SEOService } from "../../../services/seo.service";
import { LocalStorageService } from "../../../services/webApis/local-storage.service";
import { ActivatedRoute } from "@angular/router";
import { validators } from "sitemap";
import { MatDialog } from "@angular/material/dialog";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-app-review-flight-offer-add-traveller-form",
  templateUrl: "./app-review-flight-offer-add-traveller-form.component.html",
  styleUrls: ["./app-review-flight-offer-add-traveller-form.component.css"],
})
export class AppReviewFlightOfferAddTravellerFormComponent implements OnInit {
  @Input() searchRequest: any;
  @Input() payLater;
  @Input() familyMembers: any;
  @Output() setOwnerEvent = new EventEmitter();
  @Output() setPayLater = new EventEmitter();

  errorMessage: any[] = [];
  currentDate = new Date();
  form: FormGroup;
  relations: any = [];
  message: any;
  spinner: any = false;
  right: any;
  errorCheckEmail: any;
  loginBtn: any = false;
  numberOfTeravellers = [];
  email: any = null;
  filteredCountries: Observable<string[]>;
  filteredCountryInfo: Observable<string[]>;
  genderErrors: any;
  formData: any;
  travellers: any[] = [];
  errors: any = [[]];
  userFullName
  userPhone
  userEmail
  months: any = [
    { text: "January", num: "01" },
    { text: "February", num: "02" },
    { text: "March", num: "03" },
    { text: "April", num: "04" },
    { text: "May", num: "05" },
    { text: "June", num: "06" },
    { text: "July", num: "07" },
    { text: "August", num: "08" },
    { text: "September", num: "09" },
    { text: "October", num: "10" },
    { text: "November", num: "11" },
    { text: "December", num: "12" },
  ];
  Passenger: any[] = [];
  tagId: any;
  allTraveller: any[] = [];
  allTravellerType: any[] = [];
  // DO Reactive Form Array
  formGroupAddTraveller: FormGroup;
  genders: any = [
    { name: "Female", value: "F" },
    { name: "Male", value: "M" },
  ];
  filteredCountry: Observable<any>;
  countries: any[] = [];
  passportIssuedCountries: any;
  tripType: string;
  viewFamilyPart;
  userData: any;
  token = this.localStorage.getItem("flyallover_token");
  getFamliyMember: any[] = [
  ];
  formTravellerIndex;

  initFormContact(): FormGroup {
    return new FormGroup({
      CreditCardCountry:
        this.payLater == false
          ? new FormControl("")
          : new FormControl("United states"),
      CreditCardstreetAddress:
        this.payLater == false
          ? new FormControl("")
          : new FormControl("197 Bay 31 street"),
      CreditCardCity:
        this.payLater == false
          ? new FormControl("", [
            Validators.pattern("^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,}$"),
          ])
          : new FormControl("Brooklyn", [
            Validators.pattern("^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,}$"),
          ]),
      CreditCardZip:
        this.payLater == false
          ? new FormControl("", [Validators.pattern("[0-9]{1,100}")])
          : new FormControl("11214", [Validators.pattern("[0-9]{1,100}")]),
      cardHolderName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,}(?: [a-zA-Z]+){1,}$"),
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
      ]),
      phone: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^-?([0-9]\d*)?$/),
      ]),
    });
  }
  get forminfo() {
    return this.form.controls;
  }
  setItemMembers() {
    return {
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      birthOfDay: "",
      birthOfMonth: "",
      birthOfYear: "",
      familyMemberId: "",
      passport_number: "",
      expiry_date: "",
      issue_date: "",
      nationality_id: "",
      nationality_code: "",
      country_code: "",
      countryPassport: "",
      nationality: "",
      issuedCountry: "",
      traveller_gender: "",
      phone_type: "",
      PassengerType: "",
      expiryOfDay: "",
      expiryOfMonth: "",
      expiryOfYear: "",
      issueOfDay: "",
      issueOfMonth: "",
      issueOfYear: "",
    }
  }
  initForminfo(): FormGroup {
    return this.fb.group({
      // email:['',[ Validators.email,Validators.required]],
      first_name: ["", [Validators.required, Validators.pattern(/^\S*$/)]],
      middle_name: ["", Validators.pattern(/^\S*$/)],
      last_name: ["", [Validators.required, Validators.pattern(/^\S*$/)]],
      family_member_id: ["0", [Validators.required]],
      date_of_birth: ["", [Validators.required]],
      nationality_id: !this.payLater
        ? ["", [Validators.required]]
        : ["", [Validators.required]],
      nationality_code: !this.payLater
        ? ["", [Validators.required]]
        : ["", [Validators.required]],
      country_id: !this.payLater
        ? ["", [Validators.required]]
        : ["", [Validators.required]],
      country_code: !this.payLater
        ? ["", [Validators.required]]
        : ["", [Validators.required]],
      "country-code": !this.payLater
        ? ["", [Validators.required]]
        : ["", [Validators.required]],
      country: !this.payLater
        ? ["", [Validators.required]]
        : ["", [Validators.required]],
      nationality: !this.payLater
        ? ["", [Validators.required]]
        : ["", [Validators.required]],
      gender: !this.payLater
        ? ["", [Validators.required]]
        : ["", [Validators.required]],
      // phone_type: ['H', Validators.required],
      // phone: ['', [Validators.required,Validators.pattern(/^-?([0-9]\d*)?$/)]],
      // PassengerType: ['',!this.payLater?Validators.required:Validators.requiredTrue],
      PassengerType: !this.payLater ? ["", Validators.required] : [""],
      color: "#e67e22",
      passport: this.fb.group({
        passport_number: !this.payLater
          ? ["", [Validators.required]]
          : ["", [Validators.required]],
        expire_date: !this.payLater
          ? ["", [Validators.required]]
          : ["", [Validators.required]],
        issue_date: !this.payLater
          ? ["", [Validators.required]]
          : ["", [Validators.required]],
        issued_country: !this.payLater
          ? ["", [Validators.required]]
          : ["", [Validators.required]],
        country: !this.payLater
          ? ["", [Validators.required]]
          : ["", [Validators.required]],
        issueDate: this.fb.group({
          issue_date_day: !this.payLater
            ? ["", [Validators.required, Validators.maxLength(2)]]
            : ["", [Validators.required, Validators.maxLength(2)]],
          issue_date_month: !this.payLater
            ? ["", [Validators.required]]
            : ["", [Validators.required]],
          issue_date_year: !this.payLater
            ? [
              "",
              [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
              ],
            ]
            : [
              "",
              [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
              ],
            ],
        }),
        expireDate: this.fb.group({
          expiry_date_day: !this.payLater
            ? ["", [Validators.required, Validators.maxLength(2)]]
            : ["", [Validators.required, Validators.maxLength(2)]],
          expiry_date_month: !this.payLater
            ? ["", [Validators.required]]
            : ["", [Validators.required]],
          expiry_date_year: !this.payLater
            ? [
              "",
              [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
              ],
            ]
            : [
              "",
              [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
              ],
            ],
        }),
        travellerDob: this.fb.group({
          traveller_dob_day: !this.payLater
            ? ["", [Validators.required, Validators.maxLength(2)]]
            : ["", [Validators.required, Validators.maxLength(2)]],
          traveller_dob_month: ["", [Validators.required]],
          traveller_dob_year: [
            "",
            [
              Validators.required,
              Validators.minLength(4),
              Validators.maxLength(4),
            ],
          ],
        }),
      }),
    });
  }
  seoUpdate() {
    this.seoService.updateTitle("Review Flight");
    this.seoService.updateDescription(
      "Get all information about trips, flights and hotels with your profile in flyallover just sign up "
    );
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  // ***********************
  constructor(
    private fb: FormBuilder,
    private apis: ApisService,
    private seoService: SEOService,
    private localStorage: LocalStorageService,
    private _ActivatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.form = this.initFormContact();

    this.tagId = this._ActivatedRoute.snapshot.queryParamMap.get("tagId");
    this.tripType =
      this._ActivatedRoute.snapshot.queryParamMap.get("trip_type");
    this.getFamilyRelations();
  }

  ngOnInit() {
    if (this.tripType == "OpenJaw") {
      this.formData = JSON.parse(
        this.localStorage.getItem("searchRequestMultiCityPayLater")
      );
    } else {
      this.formData = JSON.parse(
        this.localStorage.getItem("searchRequestOffers")
      );
    }

    for (let i = 0; i < this.formData.adult; i++) {
      this.allTraveller.push("adult");
      this.allTravellerType.push("ADT");
    }
    for (let i = 0; i < this.formData.child; i++) {
      this.allTraveller.push("child");
      this.allTravellerType.push("CNN");
    }
    for (let i = 0; i < this.formData.lap; i++) {
      this.allTraveller.push("lap");
    }
    for (let i = 0; i < this.formData.seat; i++) {
      this.allTraveller.push("seat");
    }

    this.getCountries();

    this.formGroupAddTraveller = this.fb.group({
      formArrayAddTraveller: this.fb.array([]),
    });
    for (let index = 0; index < this.searchRequest.travellers; index++) {
      this.memberTraveller.push(this.initForminfo());
      this.getFamliyMember.push(this.setItemMembers());
    }
    this.form.valueChanges.subscribe((res: any) => {
      if (this.form.valid) {
        this.checkValidations();
      }
    });
    this.formGroupAddTraveller.valueChanges.subscribe((res: any) => {
      if (this.formGroupAddTraveller.valid) {
        this.checkValidations();
      }
    });
    this.form.get("email").valueChanges.subscribe((res: any) => {
      if (!this.form.get("email").errors) {
        this.setEmails(this.form.get("email").value);
      }
    });

    this.numberOfTeravellers.length = this.searchRequest.travellers;
    this.travellers.length = this.numberOfTeravellers.length;
    if (this.token) {
      this.getUserData();
    }
  }

  // form arrar **********************
  get memberTraveller() {
    return <FormArray>(
      this.formGroupAddTraveller.controls["formArrayAddTraveller"]
    );
  }
  getFamilyRelations() {
    this.apis.familyRelations().subscribe(
      (data: any) => {
        this.relations = data.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // auto compelete filter Country Info
  getfilterCountryInfo() {
    if (this.countries) {
      this.filteredCountryInfo = this.form
        .get("CreditCardCountry")
        .valueChanges.pipe(
          startWith(""),
          map((value) => this._filterCountriesInfo(value))
        );
    }
  }
  private _filterCountriesInfo(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["CreditCardCountry"].setErrors({
        incorrect: true,
        someProp: "Country Not Found Please Select Valid Country",
      });
    } else {
      return this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  // auto compelete filter Country
  getCountries() {
    this.apis.getCountries().subscribe(
      (data: any) => {
        this.countries = data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getfilterCountry(i) {
    if (this.countries) {
      this.filteredCountry = this.memberTraveller.controls[i]
        .get("passport")
        .get("issued_country")
        .valueChanges.pipe(
          startWith(""),
          map((value) => this._filterCountries(i, value))
        );
    }
  }
  private _filterCountries(i: number, value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.memberTraveller.controls[i]
        .get("passport")
        .get("issued_country")
        .setErrors({
          incorrect: true,
          someProp: "Country Not Found Please Select Valid Country",
        });
    } else {
      return this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  // *********************
  calculateDiff(index) {
    let thisDate = new Date();
    let dateSent = new Date(this.currentDate);

    if (
      Math.floor(
        (Date.UTC(
          thisDate.getFullYear(),
          thisDate.getMonth(),
          thisDate.getDate()
        ) -
          Date.UTC(
            dateSent.getFullYear(),
            dateSent.getMonth(),
            dateSent.getDate()
          )) /
        (1000 * 60 * 60 * 24 * 365)
      ) < 18
    ) {
      this.errorMessage[index] = "you should be older than 18";
    } else {
      this.errorMessage[index] = null;
    }
    return true;
  }
  checkDate(index, formGroupName) {
    this.memberTraveller.controls[index]
      .get("passport")
      .get(`${formGroupName}`)
      .valueChanges.subscribe((res) => {
        switch (formGroupName != undefined) {
          case formGroupName == "travellerDob": {
            this.currentDate = new Date(
              parseInt(res.traveller_dob_year),
              parseInt(res.traveller_dob_month),
              parseInt(res.traveller_dob_day),
              0,
              0,
              0
            );

            if (
              this.allTraveller[index] == "adult" &&
              this.calculateDiff(index)
            ) {
              this.memberTraveller.controls[index].patchValue({
                date_of_birth: `${res.traveller_dob_year}-${res.traveller_dob_month
                  }-${this.padLeft(res.traveller_dob_day, "0", 2)}`,
              });

              break;
            } else {
              this.memberTraveller.controls[index].patchValue({
                date_of_birth: `${res.traveller_dob_year}-${res.traveller_dob_month
                  }-${this.padLeft(res.traveller_dob_day, "0", 2)}`,
              });
              break;
            }
          }

          case formGroupName == "issueDate": {
            this.memberTraveller.controls[index].get("passport").patchValue({
              issue_date: `${res.issue_date_year}-${res.issue_date_month
                }-${this.padLeft(res.issue_date_day, "0", 2)}`,
            });
            break;
          }
          case formGroupName == "expireDate": {
            this.memberTraveller.controls[index].get("passport").patchValue({
              expire_date: `${res.expiry_date_year}-${res.expiry_date_month
                }-${this.padLeft(res.expiry_date_day, "0", 2)}`,
            });
            break;
          }

          default: {
            break;
          }
        }
      });
  }
  countryPatchValue(index) {
    this.memberTraveller.controls[index]
      .get("passport")
      .get("issued_country")
      .valueChanges.subscribe((res) => {
        this.memberTraveller.controls[index].patchValue({
          country_id: this.getCountryObject(res)?.id,
          country_code: this.getCountryObject(res)?.code,
          "country-code": this.getCountryObject(res)?.code,
          country: this.getCountryObject(res),
          nationality_id: this.getCountryObject(res)?.id,
          nationality_code: this.getCountryObject(res)?.code,
        });
        this.memberTraveller.controls[index].get("passport").patchValue({
          country: this.getCountryObject(res),
        });
        this.memberTraveller.controls[index].patchValue({
          nationality: this.getCountryObject(res),
          country: this.getCountryObject(res),
        });
      });
  }
  // get object country
  getCountryObject(country): any {
    let objCountry = this.countries.find((obj) => obj.name === country);
    console.log(`getCountryObject ===>`, objCountry);

    return objCountry;
  }
  getFamilyObject(relation) {
    const objRelation = this.relations.find((obj) => obj.name.toLowerCase() === relation.toLowerCase());
    console.log('objRelation => getFamilyObject', objRelation);
    console.log('relation => getFamilyObject', relation);

    return objRelation;
  }
  setPassengerType(i) {
    const relation = this.allTraveller[i]
    const objRelation = this.relations.find((obj) => obj.name === relation);
    return objRelation;
  }
  get formMember() {
    return this.memberTraveller.controls;
  }

  setEmails(e) {
    if (e) {
      this.message = "";
      this.right = false;
      this.errorCheckEmail = false;
      this.spinner = true;
      this.apis.checkMail(e).subscribe(
        (data: any) => {
          if (data.status) {
            this.spinner = false;
            this.right = true;
            this.email = e;
            this.errorCheckEmail = false;
          }
        },
        (err) => {
          this.right = false;
          this.spinner = false;
          this.loginBtn = true;
          this.errorCheckEmail = true;
          this.message = "The email has already been taken.";
        }
      );
    } else {
      this.spinner = false;
      this.right = false;
      this.loginBtn = false;
      this.errorCheckEmail = true;
      this.message = "You have entered an invalid email address!";
    }
  }
  checkValidations() {
    console.log(`this.formGroupAddTraveller.valid => checkValidations { ${this.formGroupAddTraveller.valid} =>  formGroupAddTraveller => { `, this.formGroupAddTraveller);
    console.log(`this.this.form.valid => checkValidations { ${this.form.valid} =>  this.form => { `, this.form);

    if (this.formGroupAddTraveller.valid && this.form.valid) {
      this.changeTravellersFormat();
      this.setOwnerEvent.emit(this.allFormData(this.travellers));
      this.setPayLater.emit({
        travellers: this.travellers,
        initFormContact: this.form,
        validTravellers: true,
      });
    } else {
    }
  }
  // format Traveller data
  changeTravellersFormat() {
    console.log('travellers =>changeTravellersFormat', this.travellers);
    console.log('=>changeTravellersFormat');

    this.travellers = [];
    for (let i = 0; i < this.numberOfTeravellers.length; i++) {
      let formTraveller = (<FormArray>(
        this.formGroupAddTraveller.get("formArrayAddTraveller")
      )).value[i];

      console.log('formTraveller =>changeTravellersFormat', formTraveller);
      var travellerGender = "";
      if (formTraveller.gender === "Male") {
        travellerGender = "M";
      }
      if (formTraveller.gender === "Female") {
        travellerGender = "F";
      }
      this.travellers.push({
        GivenName: formTraveller.first_name,
        MiddleName: formTraveller.middle_name,
        Surname: formTraveller.last_name,
        family_member_id: this.getFamliyMember[i]?.familyMemberId ? this.getFamliyMember[i]?.familyMemberId : "0",
        family_relation_id: !this.payLater
          ? this.getFamilyObject(formTraveller.PassengerType).id.toString()
          : "1",
        traveller_dob: formTraveller.date_of_birth,
        passport_number: formTraveller.passport.passport_number,
        expiry_date: formTraveller.passport.expire_date,
        issue_date: formTraveller.passport.issue_date,
        nationality_id: this.getCountryObject(
          formTraveller.passport.issued_country
        )?.id.toString(),
        nationality_code: this.getCountryObject(formTraveller.passport.issued_country)?.code,
        country_id: this.getCountryObject(
          formTraveller.passport.issued_country
        )?.id.toString(),
        country_code: this.getCountryObject(formTraveller.passport.issued_country)?.code,
        "country-code": this.getCountryObject(formTraveller.passport.issued_country)?.code,
        countryPassport: this.getCountryObject(formTraveller.passport.issued_country)?.code,
        nationality: this.getCountryObject(formTraveller.passport.issued_country)?.code,
        issuedCountry: this.getCountryObject(formTraveller.passport.issued_country)?.code,
        traveller_gender: travellerGender,
        phone_type: "H",
        PassengerType: this.getFamilyObject(formTraveller.PassengerType).passenger_type ? this.getFamilyObject(this.allTraveller[i]).passenger_type : this.setPassengerType(i),
        color: "#e67e22",
      });
    }
  }
  // format payload
  allFormData(travellerForm) {
    const object: any = {};
    const travelarFormSort = [];
    let HOF = [];
    let SPS = [];
    let FNN = [];
    HOF = travellerForm.filter((x) => x.PassengerType == "HOF");
    HOF.forEach((item) => {
      travelarFormSort.push(item);
    });
    SPS = travellerForm.filter((x) => x.PassengerType == "SPS");
    SPS.forEach((item) => {
      travelarFormSort.push(item);
    });
    FNN = travellerForm.filter((x) => x.PassengerType == "FNN");
    FNN.forEach((item) => {
      travelarFormSort.push(item);
    });
    for (let i = 0; i < travelarFormSort.length; i++) {
      object[i + 1] = {};
      object[i + 1] = travelarFormSort[i];
    }
    object.tagId = this.tagId;
    object.origin = this.searchRequest.origin;
    object.destination = this.searchRequest.destination;
    object.departure_date = this.searchRequest.departure_date;
    object.return_date = this.searchRequest.return_date;
    object.passengerscount = this.searchRequest.travellers.toString();
    object.trip_type = this.searchRequest.trip_type;
    object.origin_country = this.getOriginCountry();
    object.destination_country = this.getDestinationCountry();
    object.email = !this.token ? this.email : this.userEmail;
    object.phone = !this.token ? this.form.value.phone : this.userPhone;
    object.CreditCardCity =
      this.payLater == false ? this.form.value.CreditCardCity : "Brooklyn";
    object.CreditCardstreetAddress =
      this.payLater == false
        ? this.form.value.CreditCardstreetAddress
        : "197 Bay 31 street";
    object.cardHolderName = !this.token ? this.form.value.cardHolderName : this.userFullName;
    object.CreditCardCountry =
      this.payLater == false
        ? this.form.value.CreditCardCountry
        : "United states";
    object.CreditCardZip =
      this.payLater == false ? this.form.value.CreditCardZip : "11214";

    return object;
  }
  getRelationObject(relation): any {
    const index = this.Passenger.findIndex((obj) => obj.member === relation);
    if (index > -1) {
      return this.Passenger[index];
    }
  }
  getOriginCountry() {
    const arr = this.searchRequest.origin.split(",");
    return arr[arr.length - 1];
  }
  getDestinationCountry() {
    const arr = this.searchRequest.destination.split(",");
    return arr[arr.length - 1];
  }
  format(e) {
    e.target.value = this.padLeft(e.target.value, "0", 2);
    // console.log(e.target.value)
  }
  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  selectFamily(familyContent, i) {
    this.viewFamilyPart = this.modalService.open(familyContent, {
      centered: true,
      size: "md",
    });
    this.formTravellerIndex = i
    // this.form = i;
  }
  checkFamilySelected(memberId) {
    const memberCheck = this.getFamliyMember.find(family => family.familyMemberId === memberId);

    if (memberCheck) {
      return true;
    }
    else {
      return false;
    }
  }
  setFamilyMember(member) {

    if (this.checkFamilySelected(member.id) == false) {
      const i = this.formTravellerIndex
      let formTraveller = (<FormArray>(
        this.formGroupAddTraveller.get("formArrayAddTraveller")
      )).value[this.formTravellerIndex];
      this.getFamliyMember[i].firstName = member.first_name;
      this.getFamliyMember[i].middleName = member.middle_name;
      this.getFamliyMember[i].lastName = member.last_name;
      this.getFamliyMember[i].familyMemberId = member.id;
      this.getFamliyMember[i].gender = member.gender;
      this.getFamliyMember[i].birthOfDay = member.date_of_birth.split('-')[2];
      this.getFamliyMember[i].birthOfMonth = member.date_of_birth.split('-')[1];
      this.getFamliyMember[i].birthOfYear = member.date_of_birth.split('-')[0];
      this.getFamliyMember[i].passport_number = member.passport.passport_number;
      this.getFamliyMember[i].expiryOfDay = member.passport.expire_date.split('-')[2];
      this.getFamliyMember[i].expiryOfMonth = member.passport.expire_date.split('-')[1];
      this.getFamliyMember[i].expiryOfYear = member.passport.expire_date.split('-')[0];
      this.getFamliyMember[i].expiry_date = member.passport.expire_date;
      this.getFamliyMember[i].issueOfDay = member.passport.issue_date.split('-')[2];
      this.getFamliyMember[i].issueOfMonth = member.passport.issue_date.split('-')[1];
      this.getFamliyMember[i].issueOfYear = member.passport.issue_date.split('-')[0];
      this.getFamliyMember[i].issue_date = member.passport.issue_date;
      this.getFamliyMember[i].nationality_id = member.nationality.id;
      this.getFamliyMember[i].nationality_code = member.nationality.code;
      this.getFamliyMember[i].country_code = member.passport.issued_country.code;
      this.getFamliyMember[i].countryPassport = member.passport.issued_country.code;
      this.getFamliyMember[i].nationality = member.passport.issued_country.code;
      this.getFamliyMember[i].issuedCountry = member.passport.issued_country.name;
      this.getFamliyMember[i].PassengerType = this.setPassengerType(i);
      this.viewFamilyPart.close()
    }

  }
  removeFamilyMember() {
    const i = this.formTravellerIndex
    let formTraveller = (<FormArray>(
      this.formGroupAddTraveller.get("formArrayAddTraveller")
    )).value[this.formTravellerIndex];
    formTraveller.family_member_id = "";
    this.getFamliyMember[i].firstName = "";
    this.getFamliyMember[i].middleName = "";
    this.getFamliyMember[i].lastName = "";
    this.getFamliyMember[i].gender = "";
    this.getFamliyMember[i].birthOfDay = "";
    this.getFamliyMember[i].birthOfMonth = "";
    this.getFamliyMember[i].birthOfYear = "";
    this.getFamliyMember[i].familyMemberId = "";
    this.viewFamilyPart.close()
  }
  getUserData() {
    this.apis.showUser(this.token).subscribe(
      (res: any) => {
        console.log("user Data", res);
        this.userData = res.data;
        this.userFullName =
          this.userData?.first_name +
          " " +
          this.userData?.middle_name +
          " " +
          this.userData?.last_name;
        this.userPhone = this.userData?.phone;
        this.userEmail = this.userData?.email;
        this.form.patchValue({
          cardHolderName: this.userFullName,
          phone: this.userPhone,
          email: this.userEmail,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
