import {Component, OnInit} from '@angular/core';
import {ApisService} from '../../services/apis.service';
import {Router, ActivatedRoute} from '@angular/router';
import {SEOService} from '../../services/seo.service';
import { ViewportScroller } from '@angular/common';
import { Options, LabelType } from 'ng5-slider';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.css']
})
export class TripsComponent implements OnInit {
  word = '';
  showResultSearch: boolean = false;
  searchInput: string = '';
  page: any =0;
  pageSize ;
  data: any = [];
  about: any;
  collectionSize: number;
  dataGnrl: any;
  last_page: any;
  minValue: number = 100;
  maxValue: number = 500;
  constructor(
    private apis: ApisService,
    public activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private viewportScroller: ViewportScroller,

  ) {
    // this.seoUpdate();
   
  }


  ngOnInit(): void {
    this.getTrips();
  }
  options: Options = {
    floor: 0,
    ceil: 500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min price:</b> Rs. ' + value;
        case LabelType.High:
          return '<b>Max price:</b> Rs. ' + value;
        default:
          return 'Rs. ' + value;
      }
       
      console.log('value' + value)
    }
  };

  search(): void {
    this.apis.getVacationTitle( this.word ).subscribe((data: any) => {
      this.dataGnrl = data.data ;
      // this.data = data.data[0].trip_details;
      console.log(this.dataGnrl ,'this.data')
       
      },
      err => {
        console.log(err);
      });
      this.apis.getVacationPrice( this.word ).subscribe((data: any) => {
        this.dataGnrl = data.data ;
        // this.data = data.data[0].trip_details;
        console.log(this.dataGnrl ,'this.data')
         
        },
        err => {
          console.log(err);
        });
    console.log('تم البحث عن: ' + this.word);
  }

  getTrips() {
    // if (typeof document !== 'undefined') {
    //   window.scrollTo({top: 0, behavior: 'smooth'});
    // }
    this.apis.getTrip().subscribe((data: any) => {
      this.dataGnrl = data.data.reverse() ;
      this.collectionSize = data.meta.total;
      this.page=data.meta.current_page;
       this.pageSize = data.meta.per_page
       this.last_page = data.metalast_page
      // this.data = data.data[0].trip_details;
      console.log(this.dataGnrl ,'this.data')
       
      },
      err => {
        console.log(err);
      });
  }
  
  changePage(event) {
    // this.windowService.nativeWindow.scrollTo(0, 0);
    this.viewportScroller.scrollToPosition([0, 0]);
    console.log(event , ' eventData') ; 
    this.apis.getTrip(event).subscribe(
      (data : any) => {
        this.dataGnrl = data.data.reverse() ;
      },
      (err) => {
        console.log(err);
      }
    )

  }
 

  // seoUpdate() {
  //   this.seoService.updateTitle('FlyAllOver | Best offers For Flights');
  //   this.seoService.updateDescription('Get the latest FlyAllOver coupons offers and save your money on your vacation.');
  //   this.seoService.updateUrl();
  //   this.seoService.updateImage('https://flyallover.com/assets/images/offers/offers.jpg');
  // }
}
